<template>
  <v-container fluid>
    <v-form :readonly="!me.is.superAdmin && !me.is.transportationAdmin">
      <v-card>
        <v-card-title>Calendars</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-subheader> Display Calendar Tab to the following roles: </v-subheader>
            <div class="checkbox-wrapper">
              <v-checkbox
                class="checkbox"
                v-for="(role, i) in filteredRoles"
                :key="i"
                :value="role.id"
                v-model="config.showCalendarTab"
                :label="role.name"
                multiple
                @change="handleCalendarTab"
              ></v-checkbox>
            </div>
          </v-row>
          <v-row dense class="bg-blue">
            <v-subheader> Display Trip Calendar to the following roles: </v-subheader>
            <div class="checkbox-wrapper">
              <v-checkbox
                class="checkbox"
                v-for="(role, i) in filteredRoles"
                :key="i"
                :value="role.id"
                v-model="config.showTripCalendar"
                :label="role.name"
                :disabled="!config.showCalendarTab.includes(role.id)"
                multiple
              ></v-checkbox>
            </div>
          </v-row>
          <v-row dense>
            <v-subheader> Display Vehicle Calendar to the following roles: </v-subheader>
            <div class="checkbox-wrapper">
              <v-checkbox
                class="checkbox"
                v-for="(role, i) in filteredRoles"
                :key="i"
                :value="role.id"
                v-model="config.showVehicleCalendar"
                :label="role.name"
                :disabled="!config.showCalendarTab.includes(role.id)"
                multiple
              ></v-checkbox>
            </div>
          </v-row>
          <v-row dense class="bg-blue">
            <v-subheader> Display Driver Calendar to the following roles: </v-subheader>
            <div class="checkbox-wrapper">
              <v-checkbox
                class="checkbox"
                v-for="(role, i) in filteredRoles"
                :key="i"
                :value="role.id"
                v-model="config.showDriverCalendar"
                :label="role.name"
                :disabled="!config.showCalendarTab.includes(role.id)"
                multiple
              ></v-checkbox>
            </div>
            <v-row dense v-show="config.showDriverCalendar.includes(5)">
              <v-col>
                <v-subheader> Limit Driver Calendar entries for Drivers? </v-subheader>
                <v-radio-group v-model="config.limitDriverCalendar" row class="ml-4">
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';
import { ROLES } from '@/shared/common';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';
import { cloneDeep } from 'lodash';

export default {
  mixins: [promptBeforeLeave],
  inject: ['eventHub'],
  components: {},
  data: () => ({
    config: {
      showCalendarTab: [],
      showTripCalendar: [],
      showVehicleCalendar: [],
      showDriverCalendar: [],
      limitDriverCalendar: false,
    },
  }),
  computed: {
    ...mapGetters('user', ['me', 'roles']),
    filteredRoles() {
      return this.roles.filter((role) => role.id !== ROLES.SUPER_ADMIN);
    },
    roleOptions() {
      return this.roles.filter((e) => this.config.showCalendarTab.includes(e.id));
    },
  },
  async mounted() {
    await this.fetchItems();
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    async fetchItems() {
      this.calendarConfig = await this.getConfig('calendar');
      this.config = { ...this.config, ...this.calendarConfig };
      this.baseFormValue = cloneDeep(this.config);
    },
    async save() {
      try {
        const r = await this.setConfig({ name: 'calendar', value: this.config });
        if (r && r.done) {
          this.$myalert.success('Calendar settings saved');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    handleCalendarTab() {
      this.config.showTripCalendar = this.config.showTripCalendar.filter((e) =>
        this.config.showCalendarTab.includes(e)
      );
      this.config.showVehicleCalendar = this.config.showVehicleCalendar.filter((e) =>
        this.config.showCalendarTab.includes(e)
      );
      this.config.showDriverCalendar = this.config.showDriverCalendar.filter((e) =>
        this.config.showCalendarTab.includes(e)
      );
    },

    sortFormValues() {
      this.latestFormValue?.showCalendarTab.sort();
      this.latestFormValue?.showTripCalendar.sort();
      this.latestFormValue?.showVehicleCalendar.sort();
      this.latestFormValue?.showDriverCalendar.sort();

      this.baseFormValue?.showCalendarTab.sort();
      this.baseFormValue?.showTripCalendar.sort();
      this.baseFormValue?.showVehicleCalendar.sort();
      this.baseFormValue?.showDriverCalendar.sort();
    },
  },
  created() {
    this.eventHub.$on('saveCalendarConfig', () => this.save());
  },
  beforeDestroy() {
    this.eventHub.$off('saveBudgetCode');
  },

  watch: {
    config: {
      handler(value) {
        this.latestFormValue = cloneDeep(value);
        this.sortFormValues();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.save-btn {
  float: right;
  margin-bottom: 12px;
}
.bg-blue {
  background-color: aliceblue;
}
.checkbox-wrapper {
  display: flex;
  align-items: center !important;
  flex-wrap: wrap;
  margin-left: 16px;
  margin-right: 16px;
}
.checkbox {
  margin-right: 20px;
}
</style>
