var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          attrs: {
            readonly: !_vm.me.is.superAdmin && !_vm.me.is.transportationAdmin,
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Calendars")]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-subheader", [
                        _vm._v(
                          " Display Calendar Tab to the following roles: "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "checkbox-wrapper" },
                        _vm._l(_vm.filteredRoles, function (role, i) {
                          return _c("v-checkbox", {
                            key: i,
                            staticClass: "checkbox",
                            attrs: {
                              value: role.id,
                              label: role.name,
                              multiple: "",
                            },
                            on: { change: _vm.handleCalendarTab },
                            model: {
                              value: _vm.config.showCalendarTab,
                              callback: function ($$v) {
                                _vm.$set(_vm.config, "showCalendarTab", $$v)
                              },
                              expression: "config.showCalendarTab",
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "bg-blue", attrs: { dense: "" } },
                    [
                      _c("v-subheader", [
                        _vm._v(
                          " Display Trip Calendar to the following roles: "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "checkbox-wrapper" },
                        _vm._l(_vm.filteredRoles, function (role, i) {
                          return _c("v-checkbox", {
                            key: i,
                            staticClass: "checkbox",
                            attrs: {
                              value: role.id,
                              label: role.name,
                              disabled: !_vm.config.showCalendarTab.includes(
                                role.id
                              ),
                              multiple: "",
                            },
                            model: {
                              value: _vm.config.showTripCalendar,
                              callback: function ($$v) {
                                _vm.$set(_vm.config, "showTripCalendar", $$v)
                              },
                              expression: "config.showTripCalendar",
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-subheader", [
                        _vm._v(
                          " Display Vehicle Calendar to the following roles: "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "checkbox-wrapper" },
                        _vm._l(_vm.filteredRoles, function (role, i) {
                          return _c("v-checkbox", {
                            key: i,
                            staticClass: "checkbox",
                            attrs: {
                              value: role.id,
                              label: role.name,
                              disabled: !_vm.config.showCalendarTab.includes(
                                role.id
                              ),
                              multiple: "",
                            },
                            model: {
                              value: _vm.config.showVehicleCalendar,
                              callback: function ($$v) {
                                _vm.$set(_vm.config, "showVehicleCalendar", $$v)
                              },
                              expression: "config.showVehicleCalendar",
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "bg-blue", attrs: { dense: "" } },
                    [
                      _c("v-subheader", [
                        _vm._v(
                          " Display Driver Calendar to the following roles: "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "checkbox-wrapper" },
                        _vm._l(_vm.filteredRoles, function (role, i) {
                          return _c("v-checkbox", {
                            key: i,
                            staticClass: "checkbox",
                            attrs: {
                              value: role.id,
                              label: role.name,
                              disabled: !_vm.config.showCalendarTab.includes(
                                role.id
                              ),
                              multiple: "",
                            },
                            model: {
                              value: _vm.config.showDriverCalendar,
                              callback: function ($$v) {
                                _vm.$set(_vm.config, "showDriverCalendar", $$v)
                              },
                              expression: "config.showDriverCalendar",
                            },
                          })
                        }),
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.config.showDriverCalendar.includes(5),
                              expression:
                                "config.showDriverCalendar.includes(5)",
                            },
                          ],
                          attrs: { dense: "" },
                        },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-subheader", [
                                _vm._v(
                                  " Limit Driver Calendar entries for Drivers? "
                                ),
                              ]),
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "ml-4",
                                  attrs: { row: "" },
                                  model: {
                                    value: _vm.config.limitDriverCalendar,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.config,
                                        "limitDriverCalendar",
                                        $$v
                                      )
                                    },
                                    expression: "config.limitDriverCalendar",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: { label: "Yes", value: true },
                                  }),
                                  _c("v-radio", {
                                    attrs: { label: "No", value: false },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }